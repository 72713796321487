import { EventAggregator } from 'aurelia-event-aggregator';
import { Aurelia, autoinject, LogManager } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { UserService } from 'services/user-service';
import * as moment from 'moment';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("language-switcher");

@autoinject
export class LanguageSwitcher {
  private currentLanguage: string;

  constructor(
    private i18n: I18N,
    private router: Router,
    private userService: UserService,
    private eventAggregator: EventAggregator
  ) {
  }

  private attached() {
    this.currentLanguage = this.i18n.getLocale();
    this.eventAggregator.subscribe('lang-changed', (lang) => {
      this.currentLanguage = lang;
    });
  }

  private setLanguage(lang) {
    this.i18n.setLocale(lang);

    if (lang === 'en') {
      moment.locale('en');
    } else if (lang === 'nb-NO') {
      moment.locale('nb');
    } else if (lang === 'es') {
      moment.locale('es');
    } else {
      moment.locale('nb');
    }

    // save setting to localstorage and server
    localStorage.setItem('netlog_language', lang);

    this.userService.setLanguageCurrentUser(lang)
      .then((res) => logger.debug('Language saved'))
      .catch((err) => console.error('Error saving language: ' + err));

    this.eventAggregator.publish('lang-changed', lang);
  }
}
