import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { Visibility } from 'models/FileAttachment';
import { ErrorService } from 'services/error-service';
import { FileAttachmentService } from 'services/file-attachment-service';
import { FileService } from 'services/file-service';
import { DeleteDialogService } from './../services/delete-dialog-service';

@autoinject()
export class FileList {
  @bindable public disabled: boolean;
  @bindable private parentId: number;
  @bindable private context?: string;
  @bindable private listOnly: boolean;
  @bindable private customerId: number;
  @bindable private parentServiceUrl: string;
  @bindable private visibility?: Visibility;

  private files: Array<Models.StorageFile> = [];

  constructor(
    private fileService: FileService,
    private fileAttachmentService: FileAttachmentService,
    private errorService: ErrorService,
    private deleteDialogService: DeleteDialogService
  ) {}

  async bind() {
    this.fileAttachmentService.setApiUrl(this.parentServiceUrl);
    if (this.context) {
      this.fileAttachmentService.setContext(this.context);
    }
    await this.getFiles();
  }

  private async getFiles() {
    try {
      const files = await this.fileAttachmentService.getFiles(this.parentId, this.visibility);
      this.files = files;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async downloadFile(file: { Id: string; FileUrl: string }) {
    await this.fileService.download(file.Id, null, file.FileUrl);
  }

  async deleteFile(file: { Id: number }) {
    try {
      await this.deleteDialogService.confirmBeforeDelete(async () => {
        await this.fileAttachmentService.deleteFile(this.parentId, file.Id);
      });
      this.files = this.files.filter((x) => x.Id !== file.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async fileUploaded(event: { detail: { id: number } }) {
    try {
      await this.fileAttachmentService.addFile(this.parentId, event.detail.id, this.visibility);
      await this.getFiles();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
